.biblioteca-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
}

.filter-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.filter-container select,
.filter-container input {
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.file-list {
  display: grid;
  grid-template-columns: 1fr; /* Una columna por defecto */
  gap: 8px; /* Espacio entre los elementos */
  margin-right: 20px;
}

.file-item {
  padding: 12px 20px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  transition: background-color 0.3s ease;
  align-items: center;
}

.file-item:hover {
  background-color: #e9ecef;
}

.custom-pdf-viewer {
  height: 80vh; /* Ajusta la altura según lo necesites */
  width: 100%;
  border: none;
}

/* Dividir en dos columnas en pantallas más grandes */
@media (min-width: 768px) {
  .file-list {
    grid-template-columns: 1fr 1fr; /* Dos columnas en pantallas grandes */
  }
}

.file-type {
  color: #6c757d;
  font-weight: bold;
}

.file-item {
  width: 100%;
}

.modal-content {
  max-width: 90%;
  max-height: 80vh;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .biblioteca-container {
    padding: 15px;
  }

  .filter-container {
    flex-direction: column;
    gap: 10px;
  }

  .file-item {
    font-size: 14px;
    padding: 10px;
  }
}
