.card-container1 {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: start;
  padding: 2rem;
}

.card1 {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 280px;
  padding: 1.5rem;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.card1:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card1 h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 0.8rem;
}

.card1 p {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1.2rem;
}

.card1 button {
  background: #0078d7;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 0.6rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
}

.card1 button:hover {
  background: #005bb5;
}

.test-evaluator {
  max-width: 800px;
  margin: 2rem auto;
  background: #f9f9f9;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.test-evaluator h2 {
  text-align: center;
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1.5rem;
}

.question-block {
  margin-bottom: 2rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;
}

.question-block p {
  font-size: 1rem;
  color: #444;
  margin-bottom: 0.8rem;
  font-weight: bold;
}

.question-block label {
  display: block;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: #555;
  cursor: pointer;
}

.question-block input[type="radio"] {
  margin-right: 0.5rem;
}

button {
  display: inline-block;
  background: #0078d7;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
  transition: background 0.3s;
}

button:hover {
  background: #005bb5;
}

.results {
  text-align: center;
}

.results h3 {
  font-size: 1.3rem;
  color: #333;
}

.results p {
  font-size: 1rem;
  color: #555;
}

.results button {
  background: #0078d7;
  color: white;
  border-radius: 8px;
  padding: 0.8rem 1.5rem;
  border: none;
  cursor: pointer;
  transition: background 0.3s;
}

.results button:hover {
  background: #005bb5;
}
