/* Estilos de la barra lateral */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #141414;
  color: white;
  padding-top: 20px;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.open {
  transform: translateX(0);
}

.nav ul {
  margin-top: -30px;
  list-style-type: none;
  padding: 0;
}

.nav li {
  padding: 10px;
  cursor: pointer;
}

.nav a {
  color: white;
  text-decoration: none;
  font-size: 1.1em;
}

.nav a:hover {
  color: #ddd;
}

/* trainingSidebar.module.css */

.tourButton {
  position: relative;
  justify-content: center;
  align-items: center;
  margin-top: 95px;
  margin-left: 10px;
  background-color: transparent;
  border: 0px;
  border-radius: 0px;
  color: rgb(85, 85, 85);
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  appearance: none;
  display: inline-block;
  z-index: 10000;
}

.tourButton:hover {
  background: transparent;
}
.borderGuide {
  animation: 1.2s ease-in-out 0s infinite normal none running
    joyride-beacon-outer;
  background-color: rgba(255, 0, 68, 0.2);
  border: 2px solid rgb(255, 0, 68);
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: 50px;
  opacity: 0.9;
  width: 50px;
  transform-origin: center center;
  position: relative;
  top: -37px;
}

.pGuide {
  color: #fff;
  font-weight: 900;
  font-size: 25px;
}

.iconGuide {
  animation: 1.2s ease-in-out 0s infinite normal none running
    joyride-beacon-inner;
  background-color: rgb(255, 0, 68);
  border-radius: 50%;
  display: block;
  height: 25px;
  opacity: 0.7;
  width: 25px;
  position: relative;
  left: 12px;
}

@keyframes joyride-beacon-outer {
  0% {
    transform: scale(1);
  }

  45% {
    opacity: 0.5;
    transform: scale(0.75);
  }

  100% {
    opacity: 0.7;
    transform: scale(1);
  }
}

@keyframes joyride-beacon-inner {
  20% {
    opacity: 0.9;
  }

  90% {
    opacity: 0.7;
  }
}

/* Toggler Button para dispositivos móviles */
.toggler {
  font-size: 1.5em;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1001;
}

/* Botón de cierre en la barra lateral */
.closeBtn {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 1.5em;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
}

/* Fondo oscuro cuando la barra lateral está abierta en móviles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Responsivo */
@media (min-width: 768px) {
  .toggler {
    display: none; /* Esconde el botón toggler en pantallas grandes */
  }

  .sidebar {
    margin-top: 0px;
    transform: translateX(
      0
    ); /* Muestra la barra lateral siempre en pantallas grandes */
    width: 250px;
  }

  .overlay {
    display: none;
  }
}

/* Barra inferior de iconos para dispositivos móviles */
.mobileNavbar {
  display: none;
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }

  .toggler {
    display: none;
  }

  .mobileNavbar {
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #111;
    color: #fff;
    padding: 10px 0;
    border-top: 1px solid #444;
    z-index: 1000;
  }

  .mobileNavbar div {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    font-size: 18px;
    cursor: pointer;
  }

  .mobileNavbar div span {
    font-size: 12px;
  }
}
