/* Training.module.css */

.trainingContainer {
  display: flex;
}

.content {
  margin-left: 250px; /* Deja espacio para la barra lateral */
  padding: 10px;
  width: 100%;
}

@media (max-width: 768px) {
  .content {
    margin-left: 0; /* Sin margen en dispositivos móviles */
  }
}
