/* Register.module.css */

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.registerContainer {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.registerForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.formRow {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.formColumn {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

input,
select,
textarea,
button {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

textarea {
  resize: none;
  height: 100px;
}

button {
  background-color: #000000;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #333;
}

.error {
  color: red;
  font-size: 0.9rem;
}

.login-link {
  text-align: center;
}

.login-link p {
  margin: 0;
}

.login-link a {
  color: #000000;
  text-decoration: none;
  font-weight: bold;
}

.login-link a:hover {
  color: #0056b3;
}

input,
select,
textarea,
button {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.pickPhoto {
  font-size: 0.8rem;
  margin-left: 10px;
  color: #595959;
  font-weight: bold;
  margin-bottom: -13px;
}

/* Media Queries para pantallas pequeñas */
@media (max-width: 500px) {
  .formRow {
    flex-direction: column;
    width: 90%;
  }

  .registerContainer {
    padding: 1rem;
    width: 90%;
  }
}
