.global-container {
  padding: 20px;
}

.filter-container {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.filter-container select,
.filter-container input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  max-width: 200px;
  justify-items: center;
  align-items: center;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-emoji {
  font-size: 2rem;
  margin-bottom: 10px;
}

.card-name {
  font-size: 1rem;
  font-weight: bold;
}

.modal-content {
  max-width: 100%;
  height: auto;
}
.brand-logo {
  width: 100px;
  height: auto;
  margin-top: 10px;
  justify-self: end;
}

.card-footer {
  background: #f9f9f9;
  border: none;
  margin-top: auto; /* Empuja el footer hacia el fondo de la tarjeta */
  text-align: center; /* Centra el logo en la parte inferior horizontalmente */
}
