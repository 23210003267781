.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 10px; /* Asegura margen interno en pantallas pequeñas */
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.modal-content iframe,
.modal-content video,
.modal-content embed,
.modal-content img {
  width: 100%;
  height: 70vh;
}

/* Botón de cierre */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
  z-index: 1001; /* Asegura que esté sobre el contenido */
}

/* --- Diseño Responsive --- */

/* Para pantallas más pequeñas (teléfonos) */
@media screen and (max-width: 768px) {
  .modal-content {
    max-width: 100%; /* Ocupa todo el ancho disponible */
    height: auto; /* Ajusta la altura automáticamente */
    border-radius: 0; /* Sin esquinas redondeadas en pantallas pequeñas */
    padding: 15px; /* Margen interno más compacto */
  }

  .modal-content iframe,
  .modal-content video,
  .modal-content embed,
  .modal-content img {
    height: 60vh; /* Reduce la altura para móviles */
  }

  .close-btn {
    top: 5px; /* Ajusta posición del botón en pantallas pequeñas */
    right: 5px;
    font-size: 18px; /* Tamaño más compacto */
  }
}

/* Para pantallas ultrapequeñas (menos de 480px) */
@media screen and (max-width: 480px) {
  .modal-content {
    padding: 10px; /* Reduce aún más el padding */
  }

  .modal-content iframe,
  .modal-content video,
  .modal-content embed,
  .modal-content img {
    height: 50vh; /* Altura ajustada para pantallas pequeñas */
  }

  .close-btn {
    font-size: 16px; /* Botón más pequeño */
  }
}
