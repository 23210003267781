.especifica-container {
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.search-input {
  width: 60%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  align-self: center;
  justify-items: center;
}

.dropdowns-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.dropdown {
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  background: #f9f9f9;
}

.dropdown-btn {
  width: 100%;
  text-align: left;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  text-align: left;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  color: rgb(0, 0, 0);
  font-size: 16px;
  cursor: pointer;
}

.dropdown-btn:hover {
  background: #f9f9f9;
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dropdown-content {
  padding: 10px;
  background: #fff;
  border-top: 1px solid #ddd;
}

.dropdown-item {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f9f9f9;
}

.item-name {
  font-weight: bold;
}

.dropdown-item:last-child {
  border-bottom: none;
}

/* Rol */
.role-container {
  margin-left: 20px; /* Desplazar roles hacia la derecha */
}

.role-btn {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding: 8px;
  border: none;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  border-radius: 4px;
  margin: 3px 0;
}

/* Detalles del rol */
.dropdown-details {
  margin-left: 40px; /* Aumentar el margen de los detalles */
  font-size: 14px;
  color: #333;
}
