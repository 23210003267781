/* Header.module.css */

.header {
  position: relative;
  background-color: #141414;
  padding: 10px 20px;
  color: white;
  z-index: 1001;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5em;
  color: white;
  text-decoration: none;
}

.logo_blanco {
  width: 200px;
}

.navList {
  list-style: none;
  display: flex;
  gap: 15px;
}

.navList li {
  margin: 0;
}

.navList a {
  color: white;
  text-decoration: none;
}

.navbar-toggler {
  border-color: white !important;
}

.navbar-toggler-icon {
  display: none !important;
}

.navbar-toggler-icon::before {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.logoutButton {
  background: none;
  border: #dc3545 1px solid;
  color: #dc3545;
  cursor: pointer;
  font-size: 1em;
  align-items: center;
  border-radius: 10px;
}

.logoutButton:hover {
  background: #dc3545;
  color: #ffffff;
  cursor: pointer;
}

.editButton {
  border-radius: 10px;
}

/* Modal specific styles */
.modal-header {
  background-color: #333;
  color: #fff;
}

.modal-body {
  background-color: #f9f9f9;
}

.form-control {
  margin-bottom: 10px;
}

.editButton {
  background-color: #000000 !important;
  border-color: #ffffff !important;
  --bs-btn-bg: #030303 !important;
  --bs-btn-border-color: #000000 !important;
  border-radius: 10px;
}

.editButton:hover {
  background-color: #333;
  border-color: #ffffff;
}

.profilePhotoPreview {
  width: 150px !important; /* Set the width to your desired size */
  height: 150px !important; /* Set the height to your desired size */
  object-fit: cover;
  border-radius: 50%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.tourButton {
  color: #ffffff;
  border-radius: 10px;
  background-color: #141414;
  border-color: #141414;
  margin-top: -2px;
  font-size: 1rem;
}

.tourButton:hover {
  background-color: #004ecb;
  color: #ffffff;
}

@media (max-width: 768px) {
  .container {
    flex-direction: row;
  }

  .logo_blanco {
    width: 150px;
    margin-bottom: 10px;
  }

  .navList {
    flex-direction: row;
    gap: 10px;
  }

  .editButton,
  .logoutButton {
    width: 100%;
    font-size: 0.9em;
    margin-bottom: 0;
  }

  .buttonText {
    display: none;
  }
}

@media (max-width: 576px) {
  .logo_blanco {
    width: 120px;
    margin-bottom: 10px;
  }

  .navList {
    flex-direction: row;
    gap: 8px;
  }

  .editButton,
  .logoutButton {
    width: 100%;
    font-size: 0.8em;
    margin-bottom: 0;
  }

  .buttonText {
    display: none;
  }
}
