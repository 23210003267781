/* Login.module.css */

.container {
  position: relative;
  padding: 20px;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh; /* Ocupa toda la altura de la pantalla */
  margin-top: 20px; /* Mueve el formulario hacia arriba */
  margin-bottom: 30px;
}

.img-logo {
  width: 200px;
  margin-bottom: 5px;
  align-self: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 350px;
  padding: 30px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.login-form h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form-control {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.submit-btn {
  background-color: #000000;
  color: #fff;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #3f3f3f;
}

.register-link {
  text-align: center;
  margin-top: 20px;
}

.register-link p {
  margin: 0;
  font-size: 14px;
}

.register-link a {
  color: #000000;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.register-link a:hover {
  color: #0056b3;
}

.slider {
  margin-bottom: 50px;
  width: 75vw;
  height: auto;
  margin: auto;
  overflow: hidden;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffffa1 !important;
}

.slider .slide-track {
  display: flex;
  animation: scroll 20s linear infinite;
  width: calc(200px * 14);
  background-color: #ffffffa1;
}

.slider .slide {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 200px;
}

.slider .slide img {
  width: 100%;
}

.section {
  width: 60%;
  margin: 40px 0; /* Espacio vertical entre secciones */
  padding: 30px;
  background-color: #ffffffa1;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.section:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.section h2 {
  margin-bottom: 15px;
  font-size: 28px;
  color: #000000;
  border-bottom: 2px solid #000000; /* Línea inferior decorativa */
  display: inline-block;
  padding-bottom: 5px;
}

.section p {
  font-size: 18px;
  color: #000000;
  line-height: 1.6;
}

.section p::before {
  content: "“"; /* Comilla de apertura estilizada */
  font-size: 40px;
  color: #000000;
  vertical-align: top;
  line-height: 0;
  margin-right: 10px;
}

.section p::after {
  content: "”"; /* Comilla de cierre estilizada */
  font-size: 40px;
  color: #000000;
  vertical-align: bottom;
  line-height: 0;
  margin-left: 10px;
}

.sections {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-200px * 7));
    transform: translateX(calc(-200px * 7));
  }
}

@media (max-width: 768px) {
  .section {
    width: 90%;
    padding: 20px;
  }

  .section h2 {
    font-size: 24px;
  }

  .section p {
    font-size: 16px;
  }
}
