.banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  background-size: cover;
  background-position: center;
  color: white;
  border-radius: 10px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Ajusta la opacidad aquí */
  border-radius: 10px;
}

.title {
  position: relative;
  font-size: 2.5rem;
  z-index: 1;
}
