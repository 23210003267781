.userListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1; /* Asegura que el contenido esté delante del fondo */
  background-color: #f0f0f0;
}

.background {
  background-color: #f0f0f0;
  position: absolute;
  filter: grayscale(100%) blur(10px);
  z-index: -1; /* Asegura que el fondo esté detrás del contenido*/
  width: 100%;
}

.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 50vh;
  color: white;
  text-align: center;
  margin-bottom: 20px;
  background-image: url("/public/img/background-banner.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.banner h1 {
  margin: 0;
  font-size: 24px;
  color: #ffffff;
}

.banner p {
  margin: 10px 0 0;
  font-size: 16px;
  color: #ffffff;
}

.filters {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
  margin-bottom: 0px;
}

.brandSection {
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.brandHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.brandImage {
  width: 200px;
  margin-right: 1rem;
  border-radius: 20px;
}

.brandSection h2 {
  margin: 0;
  font-size: 20px;
  color: #000000;
}

.userCards {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 15px;
  margin-left: 30px;
}

.userCard {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  background-color: #fff;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  text-align: justify;
  margin-bottom: 20px;
}

.name {
  margin-bottom: 20px !important;
}

.userinfo > p {
  background-color: #f0f0f0;
  padding: 5px 20px 5px 20px;
  border-radius: 8px;
  border: #7d7d7d solid 1px;
  max-width: 280px;
}

.emailContainer > p {
  background-color: #f0f0f0;
  padding: 5px 5px 5px 20px;
  border-radius: 8px;
  border: #7d7d7d solid 1px;
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emailContainer > p:hover {
  overflow: visible;
  word-wrap: break-word;
  white-space: wrap;
}

.useri {
  position: relative;
  flex-direction: column;
  align-items: start;
  text-align: left;
  justify-content: left;
}

.profilePicture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  object-fit: cover;
}

.placeholder {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  color: #fff;
  border-radius: 50%;
  margin-bottom: 10px;
}

.userInfo h2 {
  font-size: 1.5em;
  margin: 10px 0;
}

.userInfo p {
  font-size: 1em;
  color: #333;
}

/* Media Queries */

@media (max-width: 768px) {
  .banner {
    height: 40vh; /* Ajusta la altura del banner en pantallas más pequeñas */
  }

  .banner h1 {
    font-size: 20px;
  }

  .banner p {
    font-size: 14px;
  }

  .filters {
    gap: 10px;
    margin-top: 30px;
  }

  .brandImage {
    width: 120px;
  }

  .userCards {
    margin-left: 0;
    justify-content: center;
  }

  .userCard {
    width: 250px; /* Ajusta el ancho de las tarjetas en pantallas más pequeñas */
  }
}

@media (max-width: 480px) {
  .banner {
    height: 30vh; /* Ajusta la altura del banner en pantallas más pequeñas */
  }

  .banner h1 {
    font-size: 18px;
  }

  .banner p {
    font-size: 12px;
  }

  .filters {
    flex-direction: column; /* Cambia la disposición de los filtros en pantallas muy pequeñas */
    gap: 10px;
    margin-top: 20px;
  }

  .brandImage {
    width: 200px;
  }

  .userCards {
    margin-left: 0;
    justify-content: center;
  }

  .userCard {
    width: 100%; /* Hace que las tarjetas ocupen todo el ancho disponible en pantallas muy pequeñas */
  }
}

.slider {
  margin-bottom: 70px;
  width: 75vw;
  height: auto;
  overflow: hidden;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  background-color: #ffffffa1 !important;
}

.slider .slide-track {
  display: flex;
  animation: scroll 20s linear infinite;
  width: calc(200px * 14);
  background-color: #ffffffa1;
}

.slider .slide {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 200px;
}

.slider .slide img {
  width: 100%;
}
